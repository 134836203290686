export const environment = {
  production: true,
  environment: 'dev',
  datadogEnv: 'development',
  traceOrigins: ['https://pcc-bff.dev.platform.linuxfoundation.org'],
  lfxHeader: 'https://cdn.dev.platform.linuxfoundation.org',
  myProfileURL: 'https://myprofile.dev.platform.linuxfoundation.org',
  linuxFoundationSFID: 'a0941000002wBz9AAE',
  apiURL: 'https://pcc-bff.dev.platform.linuxfoundation.org/development/api',
  apiV2URL: 'https://pcc-bff.dev.platform.linuxfoundation.org/development/api/v2',
  auth0: {
    domain: 'linuxfoundation-dev.auth0.com',
    clientId: 'UKtYVObwmD9j8NMl7DUxPftR1lWxnDnR',
    redirectUrl: window.location.origin,
    audience: 'https://api-gw.dev.platform.linuxfoundation.org/',
    scope: 'access:api openid email profile'
  }
};
